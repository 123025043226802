import cookie from 'react-cookies';
import moment from 'moment';

const cookieService = {};

cookieService.get = key => cookie.load(key);

cookieService.has = key => {
  const value = cookie.load(key);
  return (typeof value != 'undefined' && value != null);
};

cookieService.set = (key, value, expires) => {
  // eslint-disable-next-line no-restricted-globals
  const isInt = num => !isNaN(num) && parseInt(Number(num)) === num && !isNaN(parseInt(num, 10));

  if (isInt(expires) && expires <= 365) expires = moment().add(expires, 'days').toDate(); // int (days) to date from now
  else if (!moment(expires).isValid()) expires = undefined; // only int and dates are supported for expires

  cookie.save(key, value, {
    path: '/',
    domain: window.environment.COOKIE_DOMAIN,
    expires: expires || undefined
  });
};

cookieService.del = key => {
  cookie.remove(key);
}

export default cookieService;