import axios from 'axios';

class ApiError extends Error {
  constructor(message, status = 500, params = {}, stack) {
    super(message);

    this.message = message;
    this.status = status;
    this.params = params;
    this.stack = stack;
  }
}

export default class ApiService {
  constructor(session, errorCallback) {
    this.apiUrl = window.environment.API;
    this.session = session;
    this.errorCallback = errorCallback;
  }

  async call(method, endpoint, data = {}, upload = false, responseType, filename) {
    let params;
    if (method.toUpperCase() === 'GET') {
      params = data;
      data = undefined;
    }
    if (upload !== false) {
      const formData = new FormData();
      Object.keys(data).map(key => formData.append(key, data[key]));
      data = formData;
    }
    if (typeof responseType !== 'undefined' && typeof responseType !== 'string') responseType = undefined;

    try {
      const response = await axios({
        method,
        baseURL: this.apiUrl,
        url: `${endpoint}`,
        data,
        params,
        responseType,
        headers: {
          //'X-Requested-With': 'XMLHttpRequest',
          'Accept': 'application/json',
          'Content-Type': upload !== false ? 'multipart/form-data' : 'application/json',
          'Authorization': (typeof this.session != 'undefined' && this.session != null) ? `Bearer ${this.session}` : undefined
        },
        onUploadProgress: (typeof upload === 'function') ? progressEvent => upload(progressEvent.loaded) : undefined
      });

      if (responseType && responseType.toLowerCase() === 'blob') {
        const url = (window.webkitURL || window.URL).createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));

        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        if (filename) {
          anchor.download = filename;
          anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
        } else anchor.dataset.downloadurl = ['text/plain', anchor.href].join(':');
        anchor.click();
        // window.open(url, '_blank');
        return;
      }

      const { data: result } = response.data;
      return result;
    } catch (err) {
      // console.info(err.response);
      if (typeof err.response == 'undefined') {
        if (typeof this.errorCallback === 'function') {
          this.errorCallback({
            status: 500,
            message: 'offline',
            params: { msg: 'It seems like you lost your internet connection.' }
          });
        } else {
          console.error({
            status: 500,
            message: 'offline',
            params: { msg: 'It seems like you lost your internet connection.' }
          });
        }
      } else {
        const { status } = err.response;
        let { data: response } = err.response;

        if (responseType && responseType.toLowerCase() === 'blob') {
          try {
            response = JSON.parse(await response.text()); // blob to object
          } catch (err) {
            response = { status, error: {} };
          }
        }

        const { message: errMessage, params: errParams, stack: errStack } = response.error;

        if (status === 401 || status === 403) throw new ApiError(errMessage, status, errParams, errStack);
        else {
          if (typeof this.errorCallback === 'function') this.errorCallback({ message: errMessage, status, params: errParams });
          // else console.error({ message: errMessage, status, params: errParams });
          throw new ApiError(errMessage, status, errParams, errStack);
        }
      }
    }
  }

  // ALIASES
  get = async (endpoint, data) => this.call('GET', endpoint, data);

  post = async (endpoint, data) => this.call('POST', endpoint, data);

  put = async (endpoint, data) => this.call('PUT', endpoint, data);

  delete = async (endpoint, data) => this.call('DELETE', endpoint, data);

  upload = async (endpoint, data, progressCallback) => this.call('POST', endpoint, data, progressCallback);

  download = async (endpoint, data, filename) => this.call('GET', endpoint, data, false, 'blob', filename);


  // SETTERS
  setSession(session) { this.session = session; }

  setErrorCallback(errorCallback) { this.errorCallback = errorCallback; }

  // GETTERS
  getApiUrl() { return this.apiUrl; }

  getSession() { return this.session; }

}