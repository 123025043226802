import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import './styles.scss';

const SnackBar = forwardRef((props, ref) => {

    const [state, setState] = useState({
        isActive: false,
        message: ''
    });

    useImperativeHandle(ref, () => ({

        openSnackBar: (message = 'Something went wrong') => {
            setState({
                isActive: true,
                message: message
            });
             
        }

      }));

      useEffect(() => {
            setTimeout(() => {
              setState({
                isActive: false,
                message: ''
            });
            }, 5000);
          }, [state]);

    return (
        <div className={state.isActive ? ('snackbar show') : ('snackbar')}>
            {state.message}
        </div>
    );

});

export default SnackBar;
