import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';

// import * as serviceWorker from './common/serviceWorker';

import App from './App';

// translations
import './i18n';

setGlobal({
  countryForwards: [
    { iso: 'MX', url: 'https://www.educaro.de/mexico' },
    { iso: 'CO', url: 'https://www.educaro.de/colombia' },
    { iso: 'AR', url: 'https://www.educaro.de/argentina' },
    { iso: 'UA', url: 'https://www.educaro.de/ukraine' },
    { iso: 'TR', url: 'https://www.educaro.de/turkey' },
    { iso: 'IN', url: 'https://www.educaro.de/india' },
    { iso: 'TN', url: 'https://www.educaro.de/tunisia' },
    { iso: 'LV', url: 'https://www.educaro.de/latvia' },
  ],
  countries: ['TN', 'MX', 'LV', 'CO', 'AR', 'TR', 'UA', 'RO', 'VN', /* 'BR' 'MA', */],
  languages: ['de'], // ['en', 'es', 'lv', 'ru', 'uk', 'vi', /*'pt',*/ 'tr', 'fr', /*'ar' */],
  language: null,
  apiService: null,
  errorModal: null
});

ReactDOM.render(<App />, document.getElementById('app'));

// serviceWorker.unregister();
