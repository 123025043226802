import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import sync from 'i18next-json-sync';

// MOMENT LOCALE
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/uk';
import 'moment/locale/vi';
import 'moment/locale/tr';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/ar';
// import 'moment/locale/ru';
// import 'moment/locale/lv';
moment.locale('en');

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',

    debug: `${process.env.NODE_ENV}`.toLowerCase() !== 'production',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    ns: ['translation', 'professionalTitles'],
    defaultNS: 'translation'
  });


// SYNCING
// sync({
//   check: true,
//   files: '/public/locales/**/*.json',
//   primary: 'en',
//   //createResources: [], // Language files to create if they don't exist, e.g. ['es, 'pt-BR', 'fr']
//   space: 2,
//   lineEndings: 'LF',
//   finalNewline: false,
//   newKeysEmpty: false
// })

export default i18n;