/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// COMPONENTS
import Loader from 'core/Loader';

// STYLES
import './styles.scss';

export default function Button({
  to, href, action,
  loading,
  block, small, primary, secondary, dark, default: _default, remove,
  outline,
  className,
  children: content,
  ...props
}) {
  // SELECT COMPONENT
  let Component = p => <button type="button" {...p} />
  if (typeof to !== 'undefined' && to != null && to.trim() !== '') {
    if (to.charAt(0) === '#') Component = p => <a href={to} {...p} />
    else Component = p => <Link to={to} {...p} />
  }
  if (typeof href !== 'undefined' && href != null && href.trim() !== '') {
    Component = p => <a href={href} {...p} />
  }

  return (
    <Component
      className={classNames(
        'btn',
        action && 'action',
        block && 'block',
        small && 'small',
        primary && 'primary',
        secondary && 'secondary',
        remove && 'remove',
        dark && 'dark',
        _default && 'default',
        outline && 'outline',
        loading && 'disabled loading',
        className
      )}
      {...props}
    >
      { !loading && content }
      { loading && <>
        <span className="content">{ content }</span>
        <Loader color="light" size={small ? 18 : 24} threshold={0} className="loader" vcenterNoContainer />
      </>}
    </Component>
  );
};
