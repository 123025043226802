import React, { useState, useEffect } from 'react';

const Indicator = ({ vcenter = false, size, color, half, diameter, style = {}, ...props }) => {
  // STYLE
  let vcenterStyle = {};
  if (vcenter) {
    vcenterStyle = {
      position: 'absolute',
      width: '100%',
      marginTop: `-${size / 2}px`,
      top: '50%'
    };
  }

  return (
    <div style={{ textAlign: 'center', ...vcenterStyle, ...style }} {...props}>
      <svg viewBox={`0 0 ${size} ${size}`} stroke={color} style={{ width: `${size}px`, height: `${size}px` }}>
        <g transform="translate(1 1)" strokeWidth={2} fill="none" fillRule="evenodd">
          <circle strokeOpacity=".5" cx={half} cy={half} r={half} />
          <path d={`M${diameter} ${half}c0-9.94-8.06-${half}-${half}-${half}`}>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from={`0 ${half} ${half}`}
              to={`360 ${half} ${half}`}
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </svg>
    </div>
  );
};

const LOADING_THRESHOLD = 200;
export default function Loader({ size = 38, color = 'primary', vcenter = false, vcenterNoContainer = false, threshold = LOADING_THRESHOLD, ...props }) {
  const [ show, setShow ] = useState(!(threshold > 0));

  useEffect(() => {
    const delay = setTimeout(() => {
      setShow(true);
    }, threshold);

    return () => {
      clearTimeout(delay);
    }
  }, [threshold]);

  if (!show) return null; // until rerender ist triggered after the threshold

  const diameter = size - 2;
  const half = diameter / 2;

  // COLOR
  if (typeof color !== 'undefined' && color != null && color.charAt(0) !== '#') {
    switch (color) {
      case 'primary':
        color = '#2DC84D';
        break;
      case 'secondary':
        color = '#41B6E6';
        break;
      case 'light':
        color = '#FFFFFF';
        break;
      case 'dark':
        color = '#000000';
        break;
      case 'transparent':
        color = 'rgba(255, 255, 255, 0)';
        break;
      default:
        color = '#2DC84D';
        break;
    }
  }

  return !vcenterNoContainer
    ? <div style={{ position: 'relative', display: 'block', width: '100%', height: `${size}px`, boxSizing: 'border-box' }}>
      <Indicator size={size} color={color} half={half} diameter={diameter} vcenter={vcenter} {...props} />
    </div>
    : <Indicator size={size} color={color} half={half} diameter={diameter} vcenter={vcenter} {...props} />
  ;
}