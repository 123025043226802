import React, { Suspense, useState, useEffect, useCallback, useRef, lazy } from 'react';
import { Router, Switch, Route as RouterRoute, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useTranslation } from 'react-i18next';
import SmoothScroll from 'smooth-scroll';
import { setGlobal, useGlobal } from 'reactn';
import classNames from 'classnames';
// import GoogleAnalytics from 'react-ga';
// import GoogleTagManager from 'react-gtm-module';
// import FacebookPixel from 'react-facebook-pixel';
import moment from 'moment';

// CORE COMPONENTS
import Loader from 'core/Loader';

// COMPONENTS
import Topbar from 'components/Topbar';
import Modal from 'components/Modal';
// import Menu from 'components/Common/Menu';
import SnackBar from 'components/Common/SnackBar';
// import Footer from 'components/Footer';

// FONTAWESOME ICONS
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// SERVICES
import ApiService from 'services/api';
import cookie from 'services/cookie';

// STYLE
import 'theme/base.scss';
import 'intro.js/introjs.css';
import 'intro.js/themes/introjs-modern.css';

// PAGES
//--> authentication
const Login = lazy(() => import('pages/login'));
const SignUpPage = lazy(() => import('pages/signup'));
const SignUpConfirmationPage = lazy(() => import('pages/signup-confirmation'));
const ForgotPasswordPage = lazy(() => import('pages/forgot-password'));
const LoginAsPage = lazy(() => import('pages/login-as'));
//--> general
const SettingsPage = lazy(() => import('pages/settings'));
//--> candidate view
const FAQPage = lazy(() => import('pages/faq'))
const CandidatesIndexPage = lazy(() => import('pages/candidates/index'));
const BlogPage = lazy(() => import('pages/blog'));
const BlogPostPage = lazy(() => import('pages/blog-post'));
const CandidatesProfilePage = lazy(() => import('pages/candidates/profile'));
// const CandidatesAssessmentsPage = lazy(() => import('pages/candidates/assessments'));
//--> hospital view
// const HospitalsIndexPage = lazy(() => import('pages/hospitals/index'));
const HospitalsBatchesPage = lazy(() => import('pages/hospitals/batches'));
const HospitalsArchivedBatchesPage = lazy(() => import('pages/hospitals/batches-archived'));
const HospitalsBatchPage = lazy(() => import('pages/hospitals/batch'));
const HospitalCandidatePage = lazy(() => import('pages/hospitals/candidate'));
//--> legal
const ImpressumPage = lazy(() => import('pages/impressum'));
const TermsAndConditionsPage = lazy(() => import('pages/terms-and-conditions'));
//--> error pages
const NotFoundPage = lazy(() => import('pages/404'));
//--> dev
// const DevPage = lazy(() => import('pages/dev'));

// INIT ICON LIBRARY
FontAwesomeLibrary.add(fas, far, fal, fad, fab);

// CONSTANTS
const history = createBrowserHistory();
const smoothScroll = new SmoothScroll('a[href*="#"]', {
  speed: 300,
  speedAsDuration: true,
  easing: 'easeInOutCubic',
  updateURL: false,
  offset: 100
});
const cookieConsent = true;
const AppLoader = () => <div style={{ height: '100vh'}}><Loader size={64} vcenter vcenterNoContainer /></div>;

export default function App() {
  const errorCallback = useCallback(({ status, message, params }) => {
    let status_label;
    switch (status) {
      case 404: status_label = 'Not Found'; break;
      case 420: status_label = 'Unfulfilled Conditions'; break;
      default: break;
    }

    setGlobal({ errorModal: { status, message, params, status_label } });
  }, []);

  const snackBarRef = useRef(null);
  const [sessionToken] = useState(cookie.get(window.environment.COOKIE_SESSION_KEY));
  const [api] = useState(new ApiService(sessionToken, errorCallback));
  useEffect(() => {
    setGlobal({ apiService: api });
  }, [api]);

  // const [basePath, setBasePath] = useState(history.location.pathname.split('/')[1]);

  // ANALYTICS
  // const [ cookieConsent, setCookieConsent ] = useState(cookie.get(COOKIE_CONSENT_KEY) === 'true');
  // useEffect(() => {
  //   if (cookieConsent) {
  //     //--> Google Analytics
  //     GoogleAnalytics.initialize(window.environment.TRKINGCODE_GA, {
  //       titleCase: false,
  //       // debug: true
  //     });
  //     GoogleAnalytics.set({ anonymizeIp: true });
  //     //--> Google Tag Manager
  //     GoogleTagManager.initialize({
  //       gtmId: 'GTM-K9C9KKQ'
  //       // dataLayer: {
  //       //   userId: '001',
  //       //   userProject: 'project'
  //       // }
  //     });
  //     //--> Facebook Pixel
  //     FacebookPixel.init(window.environment.TRKINGCODE_FB, null, {
  //       autoConfig: true,
  //       // debug: true
  //     });
  //   }
  // }, []);

  // Smooth Scroll on load & history listener
  const handleHashScroll = useCallback(() => {
    const { hash } = history.location;
    if (hash && hash !== '') {
      setTimeout(() => {
        const scrollEl = document.getElementById(hash.split('#')[1]);
        if (scrollEl) smoothScroll.animateScroll(scrollEl);
      }, 500);

      history.replace({ path: history.location.pathname }); // clear url
    }
  }, []);

  useEffect(() => {
    handleHashScroll();

    const unlistenHistory = history.listen(location => {

      if (location.hash.trim().length < 1) window.scrollTo(0, 0);
      else handleHashScroll();
      // setBasePath(history.location.pathname.split('/')[1]);

      if (cookieConsent) {
        //--> Google Analytics
        // GoogleAnalytics.set({ page: location.pathname });
        // GoogleAnalytics.pageview(location.pathname);
        //--> Facebook Pixel
        // FacebookPixel.pageView();
      }
    });

    return () => {
      unlistenHistory();
    }
  }, [handleHashScroll]);

  // LANGAUGE
  const { t, i18n, ready: translationsReady } = useTranslation('translation', { useSuspense: false });
  const [AVAILABLE_LANGUAGES] = useGlobal('languages');
  const validateLanguage = useCallback((iso) => {
    if (AVAILABLE_LANGUAGES.includes(iso)) return iso;
    return AVAILABLE_LANGUAGES[0];
  }, [AVAILABLE_LANGUAGES]);
  const [language, setLanguage] = useState(i18n.language); // state necessary for re-render
  const initLanguage = useCallback((iso) => {
    iso = validateLanguage(iso);
    setGlobal({ language: iso });
    i18n.changeLanguage(iso);
    setLanguage(iso);
  }, [i18n, validateLanguage]);
  useEffect(() => {
    const l = validateLanguage(i18n.language);
    if (i18n.language !== l) {
      initLanguage(l);
      moment.locale(l);
    }
  }, [validateLanguage, initLanguage, i18n.language]);

  // AUTHENTICATION HANDLER
  const [user, setUser] = useState(null);
  const init = useCallback((session, callback) => {
    (async () => {
      if (typeof session !== 'undefined' && session != null && session.trim() !== '') cookie.set(window.environment.COOKIE_SESSION_KEY, session, 30);
      else if (session === null) session = undefined;
      else if (typeof session === 'undefined') session = cookie.get(window.environment.COOKIE_SESSION_KEY);

      api.setSession(session);

      let mUser;
      if (typeof session === 'undefined' || session === null) mUser = null;
      else {
        try {
          mUser = await api.get('me');
          if (mUser.language) initLanguage(mUser.language);
          // else initLanguage(strings.getInterfaceLanguage());
          // GoogleAnalytics.set({ userId: mUser.id });
          // GoogleTagManager.dataLayer({
          //   dataLayer: {
          //     userId: mUser.id
          //   }
          // });
        } catch (err) {
          // console.error(err);
          mUser = null;
        }
      }

      if (mUser != null) setUser(mUser);
      else {
        setUser(false);
        cookie.del(window.environment.COOKIE_SESSION_KEY);
      }

      if (typeof callback === 'function') callback();
    })();
  }, [api, initLanguage]);
  useEffect(() => init(), [init]);
  //--> update user without refetching
  const updateUserObject = (field, value) => setUser(currUser => ({ ...currUser, [field]: value }));
  //--> logout function
  const logout = useCallback(() => {
    api.post('logout'); // async, but not waiting
    // cookie.del(window.environment.COOKIE_SESSION_KEY);
    init(null, () => history.push('/login'));
  }, [api, init]);

  // TOPBAR
  const getTopbarData = useCallback(() => {
    if (user == null || user === false) return {};

    // Candidate View
    // if (basePath === 'candidates') {
    if (user.type === 1) {
      return {
        links: [
          { label: t('menu.candidate.journey'), to: '/' },
          // { label: t('menu.candidate.profile'), to: `/profile` },
          { label: t('menu.candidate.blog'), to: `/blog` },
          { label: (<><span className="mobile-hide">FAQ</span><FontAwesomeIcon icon={['fal', 'question']} /><span className="mobile-show pl-1">FAQ</span></>), to: `/faq` },
          { label: (<><FontAwesomeIcon icon="cog" /><span className="mobile-show pl-1">{t('menu.settings')}</span></>), to: `/settings` },
          { label: (<><FontAwesomeIcon icon="sign-out" /><span className="mobile-show pl-1">{t('menu.logout')}</span></>), onClick: logout }

        ]
      };
    }

    // Hospital View
    // if (basePath === 'hospitals') {
    if (user.type === 2) {
      return {
        links: [
          { label: t('menu.hospital.batches'), to: '/batches' },
          { label: t('menu.hospital.batchesArchived'), to: '/batches-archived' },
          { label: (<><FontAwesomeIcon icon="cog" /><span className="mobile-show pl-1">{t('menu.settings')}</span></>), to: `/settings` },
          { label: (<><FontAwesomeIcon icon="sign-out" /><span className="mobile-show pl-1">{t('menu.logout')}</span></>), onClick: logout }
        ]
      };
    }

    // Default
    return { links: [] };
  }, [user, logout, t]);

  // NOTIFICATIONS
  const notify = (message) => snackBarRef.current.openSnackBar(message.toString());

  // ROUTES
  const Route = useCallback(({ exact, path, redirect, redirectTo, useronly, component: PageComponent, ...props }) => {
    if (useronly && user === false) {
      redirect = true;
      redirectTo = '/login';
    }

    if ((path === '/login' || path === '/signup' || path === '/forgot-password') && user !== false && user !== null) {
      redirect = true;
      redirectTo = '/';
    }

    if (redirect === true) return (<Redirect exact={exact} from={path} to={redirectTo} />);

    return <RouterRoute exact={exact} path={path} render={routerProps => <PageComponent
      {...routerProps}
      api={api}
      user={user}
      updateUserObject={updateUserObject}
      scroll={smoothScroll.animateScroll}
      notify={notify}
      language={language}
      {...props}
    />} />;
  }, [user, api, language]);

  if (user == null || !translationsReady) return <AppLoader />;
  return (
    <Suspense fallback={<AppLoader />}>
      <Router history={history}>
        <SnackBar ref={snackBarRef} />

        <Switch>

          {/* Login / SignUp / Forgot Password */}
          <Route exact path="/login" component={Login} reinitialize={init} initLanguage={initLanguage} />
          <Route exact path="/signup" component={SignUpPage} reinitialize={init} initLanguage={initLanguage} />
          <Route exact path="/confirm/:token" component={SignUpConfirmationPage} reinitialize={init} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} reinitialize={init} />
          <Route exact path="/login-as/:token" component={LoginAsPage} reinitialize={init} />

          {/* Legal */}
          <Route exact strict path="/impressum" component={ImpressumPage} />
          <Route exact strict path="/terms-and-conditions" component={TermsAndConditionsPage} />
          {/* <Redirect exact strict from="/datenschutzerklärung" to="/impressum#datenschutzerklaerung" />
          <Redirect exact strict from="/datenschutzerklaerung" to="/impressum#datenschutzerklaerung" />
          <Redirect exact strict from="/haftungsausschluss" to="/impressum#haftungsausschluss" />
          <Redirect exact strict from="/impressum-datenschutz/" to="/impressum" /> */}

          {/* Application */}
          <RouterRoute render={() => <>
            <Topbar data={{ ...getTopbarData(), chainInfo: user.chain_info }} />

            <main>
              {/* { basePath === 'hospitals' && <Menu data={[
                // { label: 'Overview', to: '/hospitals/' },
                { label: 'Batches', to: '/hospitals/batches' }
              ]} />} */}

              <section className={classNames('page'/*, basePath === 'hospitals' && 'page-menu'*/)}>
                {user === false && <Redirect exact path="/" to="/login" />}
                {/* CANDIDATES */}
                {user.type === 1 && <Switch>
                  <Route exact useronly path="/" component={CandidatesIndexPage} initLanguage={initLanguage} />
                  <Route exact useronly path="/blog" component={BlogPage} />
                  <Route exact useronly path="/blog/:slug" component={BlogPostPage} />
                  <Route exact useronly path="/profile" component={CandidatesProfilePage} />
                  <Route exact useronly path="/settings" component={SettingsPage} initLanguage={initLanguage} />
                  <Route exact useronly path="/faq" component={FAQPage} initLanguage={initLanguage} />
                  {/* <Route exact useronly path="/assessments" component={CandidatesAssessmentsPage} /> */}

                  {/* Errors */}
                  {/* <Route exact path="/dev" component={DevPage} /> */}
                  <Route component={NotFoundPage} />
                </Switch>}

                {/* EMPLOYERS */}
                {user.type === 2 && <Switch>
                  {/* <Route exact useronly path="/" component={HospitalsIndexPage} /> */}
                  <Redirect exact path="/" to="/batches" />
                  <Route exact useronly path="/batches" component={HospitalsBatchesPage} />
                  <Route exact useronly path="/batches-archived" component={HospitalsArchivedBatchesPage} />
                  <Route exact useronly path="/batches/:id" component={HospitalsBatchPage} />
                  <Route exact useronly path="/batches/:batchId/candidates/:id" component={HospitalCandidatePage} />
                  <Route exact useronly path="/settings" component={SettingsPage} />

                  {/* Errors */}
                  <Route component={NotFoundPage} />
                </Switch>}
              </section>
            </main>

            {/* <Footer /> */}
          </>} />

        </Switch>

        <ErrorModal />
      </Router>
    </Suspense>
  );
}

function ErrorModal() {
  const [data] = useGlobal('errorModal');

  const close = () => {
    setGlobal({ errorModal: null });
  }

  if (data == null) return null;
  return (
    <Modal
      title={data.status_label || `${data.status} ERROR`}
      onConfirm={close}
    >
      {data.params ? data.params.msg : data.message}
      {data.params && Object.keys(data.params).filter(paramKey => paramKey !== 'msg').length > 0 && <ul>
        {Object.keys(data.params).filter(paramKey => paramKey !== 'msg').map(paramKey => <li key={paramKey}><b>{paramKey} &rarr; </b>{data.params[paramKey]}</li>)}
      </ul>}
    </Modal>
  );
}
