/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

const SPRING_START = '03-20';
const SUMMER_START = '06-21';
const FALL_START = '09-22';
const WINTER_START = '12-21';

export default function Topbar({ data }) {
  const [open, setOpen] = useState(false);
  const toggle = (func, doToggle = true) => {
    if (typeof func === 'function') func();
    if (doToggle) setOpen(!open);
  }

  const getChainPeriod = ({ tag, stamp }) => {
    let result = { title: '', info: '' };
    switch (tag) {
      case 'registration': {
        // result.title = 'Registration phase';
        break;
      }
      case 'evaluation': {
        // result.title = 'Assessment phase';
        break;
      }
      case 'program': {
        // result.title = 'Migration program';
        const date = moment(stamp, 'YYYY-MM-DD HH:mm:ss');
        if (!date.isValid()) return result;
        const year = date.format('YYYY');

        if (date.isBetween(`${year}-${SPRING_START}`, `${year}-${SUMMER_START}`, undefined, '[)')) result.info = `Spring ${year} Batch`;
        else if (date.isBetween(`${year}-${SUMMER_START}`, `${year}-${FALL_START}`, undefined, '[)')) result.info = `Summer ${year} Batch`;
        else if (date.isBetween(`${year}-${FALL_START}`, `${year}-${WINTER_START}`, undefined, '[)')) result.info = `Fall ${year} Batch`;
        else result.info = `Winter ${year} Batch`;

        break;
      }
      default:
        result = null;
        break;
    }
    return (result && <span className='info'>
      { result.title && <>{ result.title }<br /></> }
      { result.info }
    </span>);

  };

  const { links = [], logo = true, logoLink = '/', logoTag, chainInfo = {} } = data;

  return (
    <div id="topbar">
      <span className="logo-container">
        {logo && <Link to={logoLink} className="logo-click">
          <img src="/images/logo-white.png" alt="educaro" className="logo" />
          {logoTag && <span className="logo-tag">{logoTag.charAt(0).toUpperCase() + logoTag.substring(1)}</span>}
        </Link>}
        {getChainPeriod(chainInfo)}
      </span>
      <nav className={classNames(!logo && 'nologo', open && 'open')}>
        {links.map((link, linkIndex) => <Fragment key={linkIndex}>
          {link.to
            ? <Link to={link.to} onClick={() => toggle(link.onClick)}>{link.label}</Link>
            : <a onClick={() => toggle(link.onClick)}>{link.label}</a>
          }
        </Fragment>)}
      </nav>

      <div className={classNames('hamburger', open && 'open')} onClick={toggle}><span /><span /><span /><span /></div>
    </div>
  );
}