import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

// CORE COMPONENTS
import Button from 'core/Button';

// STYLES
import './styles.scss';

export default function Modal({
  title,
  children: content,
  small,
  large,
  onClose: doClose = () => {},
  confirmButton = 'Okay',
  confirmLoading,
  confirmDisabled,
  onConfirm: doConfirm,
  abortButton
}) {
  if (typeof doConfirm !== 'function') doConfirm = doClose;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className={classNames(
        'modal',
        small && 'small',
        large && 'large'
      )}>
        { title && <div className="modal-title">{ title }</div> }
        <div className="modal-content">{ content }</div>
        <div className="modal-footer">
          { abortButton && <Button small onClick={doClose}>{ abortButton }</Button> }
          <Button primary small onClick={doConfirm} loading={confirmLoading} disabled={confirmDisabled}>{ confirmButton }</Button>
        </div>
      </div>
    </div>
  , document.getElementById('app-modal'));
};
